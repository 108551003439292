import { lazy } from 'react';

const Admin = lazy(() => import(/* webpackChunkName: "portal" */ './index').then((module) => ({default: module.Admin})));
const AdminV2 = lazy(() => import(/* webpackChunkName: "portal" */ './index').then((module) => ({default: module.AdminV2})));
const AdminDropdownManagementRedirect = lazy(() => import(/* webpackChunkName: "portal" */ './index').then((module) => ({default: module.AdminDropdownManagementRedirect})));
const AdminRedirect = lazy(() => import(/* webpackChunkName: "portal" */ './index').then((module) => ({default: module.AdminRedirect})));
const DashboardPage = lazy(() => import(/* webpackChunkName: "portal" */ './index').then((module) => ({default: module.DashboardPage})));
const DashboardPageV2 = lazy(() => import(/* webpackChunkName: "portal" */ './index').then((module) => ({ default: module.DashboardPageV2 })));
const AnalyticsDashboard = lazy(() => import(/* webpackChunkName: "portal" */ './index').then((module) => ({default: module.AnalyticsDashboard})));
const Landing = lazy(() => import(/* webpackChunkName: "portal" */ './index').then((module) => ({default: module.Landing})));
const Login = lazy(() => import(/* webpackChunkName: "portal" */ './index').then((module) => ({default: module.Login})));
const LoginV2 = lazy(() => import(/* webpackChunkName: "portal" */ './index').then((module) => ({default: module.LoginV2})));
const LiveFeed = lazy(() => import(/* webpackChunkName: "portal" */ './index').then((module) => ({default: module.LiveFeed})));
const Profile = lazy(() => import(/* webpackChunkName: "portal" */ './index').then((module) => ({default: module.Profile})));
const QrCodes = lazy(() => import(/* webpackChunkName: "portal" */ './index').then((module) => ({default: module.QrCodes})));
const Register = lazy(() => import(/* webpackChunkName: "portal" */ './index').then((module) => ({default: module.Register})));
const LinkAccount = lazy(() => import(/* webpackChunkName: "portal" */ './index').then((module) => ({default: module.LinkAccount})));
const AccountLinked = lazy(() => import(/* webpackChunkName: "portal" */ './index').then((module) => ({default: module.AccountLinked})));
const ResetPassword = lazy(() => import(/* webpackChunkName: "portal" */ './index').then((module) => ({default: module.ResetPassword})));
const StatusDashboard = lazy(() => import(/* webpackChunkName: "portal" */ './index').then((module) => ({default: module.StatusDashboard})));
const BranchForm = lazy(() => import(/* webpackChunkName: "portal" */ './index').then((module) => ({default: module.BranchForm})));
const BranchesPage = lazy(() => import(/* webpackChunkName: "portal" */ './index').then((module) => ({default: module.BranchesPage})));
const IndividualAccountPage = lazy(() => import(/* webpackChunkName: "portal" */ './index').then((module) => ({default: module.IndividualAccountPage})));

export {
  Admin,
  AdminV2,
  AdminRedirect,
  AdminDropdownManagementRedirect,
  DashboardPage,
  DashboardPageV2,
  AnalyticsDashboard,
  Landing,
  Login,
  LoginV2,
  LiveFeed,
  Profile,
  QrCodes,
  Register,
  LinkAccount,
  AccountLinked,
  ResetPassword,
  StatusDashboard,
  BranchForm,
  BranchesPage,
  IndividualAccountPage,
};
